<!--
 * @Author: zhaoyang
 * @Date: 2023-04-25 11:00:25
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-26 14:24:00
-->

<template>
    <div class="ec-form">
        <template v-for="(option,index) in options.fieldDefs">
            <template v-if="option.hide === undefined || !option.hide(formData)">
                <input-item
                    v-if="!$slots[option.field]"
                    ref="input"
                    :key="index"
                    v-model="formData[option.field]"
                    :form-data="formData"
                    :class="{mt:index!==0}"
                    :ctx="ctx || $parent"
                    v-bind="option"
                    @change="onChange"
                    @validateChange="onValidateChange"
                >
                    <slot
                        v-if="$scopedSlots[`${option.field}.before`]"
                        slot="before"
                        :name="`${option.field}.before`"
                    />
                    <slot
                        v-if="$scopedSlots[`${option.field}.after`]"
                        slot="after"
                        :name="`${option.field}.after`"
                    />
                </input-item>
                <slot
                    v-else
                    :name="option.field"
                />
            </template>
        </template>
    </div>
</template>

<script>
import InputItem from './input-item';

export default {
    name: 'EcForm',

    components: {
        InputItem
    },

    props: {
        options: {
            type: Object,
            default: () => ({})
        },

        ctx: {
            type: Object,
            default: null
        }
    },

    data() {
        const formData = {};
        const errorMap = {};
        this.options.fieldDefs.forEach(option => {
            formData[option.field] = option.defaultValue ?? '';
            errorMap[option.field] = [];
        });

        return {
            formData,
            errorMap,
            errorInfo: []
        };
    },

    methods: {
        onChange(options) {
            this.$emit('change', options);
        },

        getValues() {
            const formData = JSON.parse(JSON.stringify(this.formData));
            this.options.fieldDefs.forEach(option => {
                const value = formData[option.field];
                const isHide = option?.hide && option?.hide?.(formData);
                if (isHide || this.$slots[option.field]) {
                    delete formData?.[option.field];

                    return;
                }

                if (option?.format?.unformat && value) {
                    formData[option.field] = option.format.unformat(value);
                }
            });

            return formData;
        },

        getFieldValue(field) {
            return this.formData[field];
        },

        setFieldValue(field, val = '') {
            this.formData[field] = val;
        },

        onValidateChange({field, errorInfo}) {
            this.errorMap[field] = errorInfo;
            const hasError = Object.keys(this.errorMap).some(item => this.errorMap[item]?.length);
            this.$emit('validateChange', {
                hasError,
                errorMap: this.errorMap
            });
        },

        validator() {
            this.$refs.input.forEach(item => {
                if (!item.hasInputed) {
                    item.validateNoInputVal();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .ec-form {
        .mt {
            margin-top: 0.15rem;
        }
    }
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ec-form" },
    [
      _vm._l(_vm.options.fieldDefs, function (option, index) {
        return [
          option.hide === undefined || !option.hide(_vm.formData)
            ? [
                !_vm.$slots[option.field]
                  ? _c(
                      "input-item",
                      _vm._b(
                        {
                          key: index,
                          ref: "input",
                          refInFor: true,
                          class: { mt: index !== 0 },
                          attrs: {
                            "form-data": _vm.formData,
                            ctx: _vm.ctx || _vm.$parent,
                          },
                          on: {
                            change: _vm.onChange,
                            validateChange: _vm.onValidateChange,
                          },
                          model: {
                            value: _vm.formData[option.field],
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, option.field, $$v)
                            },
                            expression: "formData[option.field]",
                          },
                        },
                        "input-item",
                        option,
                        false
                      ),
                      [
                        _vm.$scopedSlots[option.field + ".before"]
                          ? _vm._t(option.field + ".before", null, {
                              slot: "before",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$scopedSlots[option.field + ".after"]
                          ? _vm._t(option.field + ".after", null, {
                              slot: "after",
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._t(option.field),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: zhaoyang
 * @Date: 2023-05-13 13:47:56
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-19 17:06:49
-->

<template>
    <van-popup
        v-bind="popupOption"
        v-on="$listeners"
    >
        <div
            class="ec-popup"
            :style="contentStyle"
        >
            <slot />
        </div>
    </van-popup>
</template>

<script>
import {Popup} from 'vant';

export default {
    name: 'EcPopup',

    components: {
        [Popup.name]: Popup
    },

    props: {
        contentStyle: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        popupOption() {
            return {
                round: true,
                closeable: true,
                position: 'bottom',
                ...this.$attrs
            };
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~easycash-h5/common/style/variables";

.ec-popup {
    box-sizing: border-box;
    background: linear-gradient(to bottom, #d8ffdf 0%, #fff 0.5rem);
    padding: 0 0.15rem 0.15rem;
    background-color: #fff !important;
}
</style>

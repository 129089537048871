var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-popup",
    _vm._g(_vm._b({}, "van-popup", _vm.popupOption, false), _vm.$listeners),
    [
      _c(
        "div",
        { staticClass: "ec-popup", style: _vm.contentStyle },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
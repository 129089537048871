<!--
 * @Author: zhaoyang
 * @Date: 2023-03-07 17:06:40
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-02-29 12:17:34
-->

<template>
    <div class="input-item">
        <div
            class="input-box"
            :class="{
                focus: isFocus,
                error: errorTip
            }"
        >
            <div
                v-if="labelActive"
                class="label"
                :class="{
                    'focus-color': isFocus,
                    'error-color': errorTip
                }"
                :style="{backgroundColor: labelBg}"
            >
                {{ getLabel() }}
            </div>
            <div class="input-wrap">
                <div
                    class="before"
                >
                    <slot name="before" />
                </div>
                <textarea
                    v-if="type==='textarea'"
                    id="input"
                    ref="input"
                    v-bind="$attrs"
                    class="input-style textarea-style"
                    :style="{
                        resize: autoResizeTextarea ? 'none' : 'vertical'
                    }"
                    :class="{'input-style-error': errorTip}"
                    :placeholder="curPlaceholder"
                    :rows="rows"
                    :disabled="getDisabled()"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                />
                <template v-else-if="type === 'select'||type === 'cascader' || type === 'bankCard'">
                    <div
                        class="input-style"
                        @click="onClick"
                    >
                        <span
                            v-if="!labelActive"
                            class="placeholder"
                        >
                            {{ getLabel() }}
                        </span>
                        <span v-else>{{ pickerLabel }}</span>
                    </div>
                    <ec-popup
                        v-model="showPopup"
                        :closeable="type==='bankCard'"
                        :close-on-click-overlay="false"
                        :content-style="{
                            background: '#f4f4f4',
                            padding: '0.1rem'
                        }"
                        @click-close-icon="onCancel"
                    >
                        <template v-if="!$slots['popup']">
                            <van-picker
                                v-if="type === 'select'"
                                ref="picker"
                                show-toolbar
                                :confirm-button-text="$t('form.confirm')"
                                :cancel-button-text="$t('form.cancel')"
                                v-bind="contentProps"
                                value-key="label"
                                :columns="columnsData"
                                @confirm="onConfirm"
                                @cancel="onCancel"
                            />
                            <van-cascader
                                v-if="type === 'cascader'"
                                v-model="cascaderValue"
                                :options="columnsData"
                                :placeholder="$t('form.select')"
                                active-color="#03DA8B"
                                v-bind="contentProps"
                                @close="onCancel"
                                @change="onChange"
                                @finish="onFinish"
                            />
                            <div
                                v-if="type === 'bankCard'"
                                class="bank-card"
                            >
                                <div class="search-wrap">
                                    <van-search
                                        v-model="searchValue"
                                        background="rgba(0,0,0,0)"
                                        @input="onSearch"
                                        @clear="onClear"
                                    />
                                </div>
                                <div class="bank-box">
                                    <div
                                        v-for="(item, index) in columnsDataFiletr"
                                        :key="index"
                                        class="list-item"
                                        @click="selectCard(item)"
                                    >
                                        <div class="item-left">
                                            <img
                                                class="item-logo"
                                                :src="item.logoUrl"
                                            >
                                            <span class="item-text">
                                                {{ item.label }}
                                            </span>
                                        </div>
                                        <div
                                            v-if="item.tag"
                                            class="item-right"
                                        >
                                            {{ item.tag }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <slot
                            v-else
                            name="popup"
                        />
                    </ec-popup>
                </template>
                <input
                    v-else
                    id="input"
                    ref="input"
                    class="input-style"
                    autocomplete="off"
                    v-bind="$attrs"
                    :class="{'input-style-error': errorTip}"
                    :placeholder="curPlaceholder"
                    :type="type"
                    :disabled="getDisabled()"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                >
                <div class="after">
                    <slot name="after">
                        <div
                            v-if="type==='select' || type === 'bankCard'"
                            class="arrow"
                        />
                    </slot>
                </div>
            </div>
        </div>
        <div
            v-if="errorTip || (tip && isFocus)"
            class="tip"
            :class="{
                'focus-color': isFocus,
                'error-color': errorTip
            }"
        >
            <span v-if="isFocus && !errorTip">
                {{ $t(tip) }}
            </span>
            <span v-if="errorTip">{{ errorTip }}</span>
        </div>
    </div>
</template>

<script>
import Cleave from 'cleave.js';
import {debounce} from 'lodash';
import {Picker, Cascader, Search} from 'vant';

import EcPopup from '../ec-popup';

export default {
    name: 'InputItem',

    components: {
        EcPopup,
        [Search.name]: Search,
        [Picker.name]: Picker,
        [Cascader.name]: Cascader
    },

    props: {
        formData: {
            type: Object,
            default: () => ({})
        },
        contentProps: {
            type: Object,
            default: () => ({})
        },
        rows: {
            type: Number,
            default: 1
        },
        field: {
            type: String,
            default: ''
        },
        label: {
            type: [String, Function],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        labelBg: {
            type: String,
            default: '#f4f4f4'
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: [Array, String, Number],
            default: ''
        },
        disabled: {
            type: [Boolean, Function]
        },
        tip: {
            type: String,
            default: ''
        },
        useCleave: {
            type: Object,
            default: null
        },
        raw: {
            type: Boolean,
            default: true
        },
        format: {
            type: Object,
            default: () => ({})
        },
        rules: {
            type: Array,
            default: () => ([])
        },
        autoResizeTextarea: {
            type: Boolean,
            default: false
        },
        columns: {
            type: [Object, Function],
            default: () => ({})
        },
        required: {
            type: Boolean,
            default: false
        },
        ctx: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        const {value, formatVal} = this;
        const formatedVal = formatVal(value);

        return {
            searchValue: '',
            cascaderValue: '',
            columnsData: [],
            columnsDataFiletr: [],
            showPopup: false,
            isFocus: false,
            cleave: null,
            formatedVal,
            errorTip: '',
            errorInfo: [],
            pickerLabel: '',
            hasInputed: false
        };
    },

    computed: {
        labelActive() {
            const {isFocus, value} = this;

            return isFocus || value;
        },

        curPlaceholder() {
            return this.labelActive ?
                ''
                : (this.placeholder || this.getLabel());
        }
    },

    watch: {
        value(val) {
            const {$refs: {input}, useCleave, formatVal, raw, cleave, field} = this;
            this.$emit('change', {field, value: val});
            if (useCleave) {
                if (!cleave) return;
                if (raw) {
                    if (val === cleave.getRawValue()) return;
                    cleave.setRawValue(val);
                } else {
                    if (val === cleave.getFormattedValue()) return;
                    input.value = val;
                }
            } else {
                if (!input || input.value === val) return;
                input.value = formatVal(val);
            }
        }
    },

    mounted() {
        const {
            useCleave,
            $refs: {input},
            onValueChanged,
            formatedVal
        } = this;

        if (useCleave) {
            this.cleave = new Cleave(input, {
                ...useCleave,
                onValueChanged
            });
            if (formatedVal) {
                this.cleave.setRawValue(formatedVal);
            }
        } else if (input) {
            input.value = formatedVal;
            this.$emit('input', formatedVal);
        }

        if (this.type === 'textarea' && this.autoResizeTextarea && this.formatedVal) {
            this.autoResizeTextareaFn();
        }

        if (this.columns.query || typeof this.columns === 'function') {
            this.getColumnsData();
        }

        this.validateVal(formatedVal, true);
    },

    methods: {
        getLabel() {
            const {ctx, label, formData} = this;
            if (typeof label === 'function') {
                return this.$t(label({ctx, formData}));
            }

            return this.$t(label);
        },

        getDisabled() {
            const {disabled, ctx, formData} = this;
            if (typeof disabled === 'function') {
                return disabled({ctx, formData});
            }

            return disabled;
        },

        onClear() {
            this.searchValue = '';
            this.columnsDataFiletr = [...this.columnsData];
        },

        onSearch: debounce(function(value) {
            this.columnsDataFiletr = this.columnsData.filter(({label}) => {
                return label.includes(value);
            });
        }, 50),

        onConfirm(item) {
            let value;
            let label;
            if (Array.isArray(item)) {
                const values = this.$refs.picker.getValues();
                value = values.map(pickItem => pickItem.value);
                label = values.map(pickItem => pickItem.label).join(' ');
            } else {
                value = item.value;
                label = item.label;
            }

            this.pickerLabel = label;
            this.validateVal(value);
            this.$emit('input', value);
            this.showPopup = false;
            this.isFocus = false;
        },

        searchTree(data, value, callback) {
            data.forEach((item, index) => {
                if (item.value === value) {
                    return callback(item, index);
                }

                if (item.children) {
                    return this.searchTree(item.children, value, callback);
                }

                return null;
            });
        },

        async onChange({tabIndex, value}) {
            if (tabIndex === 3) return;
            const {columns} = this;
            const {data: {body}} = await columns.query({params: {pid: value}});
            this.searchTree(this.columnsData, value, item => {
                item.children = tabIndex === 2 ? body : columns.parse(body);
            });
        },

        onFinish({selectedOptions}) {
            this.showPopup = false;
            this.isFocus = false;
            const value = selectedOptions.map(option => option.label).join(',');
            this.pickerLabel = value;
            this.validateVal(value);
            this.$emit('input', value);
        },

        selectCard({value, label}) {
            this.pickerLabel = label;
            this.validateVal(value);
            this.$emit('input', value);
            this.showPopup = false;
            this.isFocus = false;
        },

        onCancel() {
            this.showPopup = false;
            this.isFocus = false;
            this.validateVal(this.value);
        },

        async getColumnsData() {
            const {columns} = this;
            let result = [];
            if (typeof columns === 'function') {
                this.columnsData = columns(this);
                this.columnsDataFiletr = [...this.columnsData];

                return;
            }

            switch (typeof columns.query) {
            case 'string': {
                const enumMap = this.$t(columns.query);
                result = Object.keys(enumMap).map(key => ({label: enumMap[key], value: key}));
                break;
            }

            case 'function': {
                const {data: {body}} = await columns.query();
                result = body;
                break;
            }

            default: {
                break;
            }
            }

            this.columnsData = columns.parse ? columns.parse(result) : result;
            this.columnsDataFiletr = [...this.columnsData];
        },
        onClick() {
            this.showPopup = true;
            this.hasInputed = true;
            this.isFocus = !this.isFocus;
        },
        autoResizeTextareaFn() {
            const textarea = this.$refs.input;
            textarea.style.height = `${textarea.scrollTop + textarea.scrollHeight }px`;
        },

        validateNoInputVal() {
            this.hasInputed = true;
            this.validateVal(this.formatedVal);
        },

        validateVal(value, isMounted) {
            const {hasInputed, required, field, type} = this;
            if (!hasInputed && required) {
                if (!value) {
                    this.$emit('validateChange', {
                        field,
                        isError: true,
                        errorInfo: [this.$t('form.required')]
                    });
                }

                return;
            }

            if (isMounted) return;
            const rules = required ? [
                ...this.rules,
                {
                    validator: val => {
                        if (['select', 'cascader', 'bankCard'].includes(type)) {
                            return !!val;
                        }

                        return !!val?.trim?.();
                    },
                    message: this.$t('form.required')
                }
            ] : this.rules;

            if (rules.length) {
                let tip = '';
                const errorInfo = [];
                rules.forEach(({validator, message}) => {
                    if (!validator(value)) {
                        const msg = this.$t(message);
                        errorInfo.push(msg);
                        if (!tip) tip = msg;
                    }
                });

                this.$emit('validateChange', {
                    field,
                    isError: !!tip,
                    errorInfo
                });

                this.errorInfo = errorInfo;
                this.errorTip = tip;
            }
        },

        formatVal(val) {
            if (!val) return val;
            if (this.format?.format) {
                return this.format.format(val);
            }

            return val;
        },

        onValueChanged({target: {rawValue, value}}) {
            const {raw, formatVal} = this;
            const formatedVal = formatVal(value);
            const formatedRawVal = formatVal(rawValue);
            this.$emit('input', raw ? formatedRawVal : formatedVal);
            this.validateVal(formatedRawVal);

            if (rawValue !== formatedRawVal && this.cleave) {
                this.cleave.setRawValue(formatedRawVal);
            }
        },

        onInput(event) {
            this.hasInputed = true;
            if (!this.useCleave) {
                const value = this.formatVal(event.target.value);
                event.target.value = value;
                this.$emit('input', value);
                this.validateVal(value);
            }

            if (this.type === 'textarea' && this.autoResizeTextarea) {
                this.autoResizeTextareaFn(event);
            }
        },

        onFocus(evt) {
            this.isFocus = true;
            if (this.$listeners.focus) {
                this.$listeners.focus(evt);
            }
        },

        onBlur(evt) {
            setTimeout(() => {
                this.isFocus = false;
                if (this.$listeners.blur) {
                    this.$listeners.blur(evt);
                }
            }, 350);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~easycash/common/style/variables/input.scss";

.input-item {
    box-sizing: border-box;
    padding-top: 0.1rem;

    .focus-color {
        color: $focusColor !important;
    }

    .error-color {
        color: $errorColor !important;
    }

    .input-box {
        box-sizing: border-box;
        padding: 0.12rem;
        border-radius: 8px;
        border: 1px solid $initColor;
        position: relative;

        .label {
            position: absolute;
            left: 0.1rem;
            top: -0.1rem;
            box-sizing: border-box;
            padding: 0 0.05rem;
            font-size: 12px;
            font-family: Helvetica;
            color: $initColor;
            line-height: 16px;
        }

        .input-wrap {
            display: flex;
            align-items: center;

            .input-style {
                min-height: 0.18rem;
                font-size: 14px;
                font-family: Helvetica;
                font-weight: bold;
                color: $initFontColor;
                line-height: 0.18rem;
                flex: 1;
                background-color: transparent;
                padding: 0;
                border: 0;
                outline: 0;

                .placeholder {
                    font-size: 12px;
                    font-family: Helvetica;
                    color: $initColor;
                    line-height: 16px;
                    font-weight: 400;
                }
            }

            .after {
                .arrow {
                    width: 0.06rem;
                    height: 0.06rem;
                    border-bottom: 2px solid #ccc;
                    border-right: 2px solid #ccc;
                    transform: rotate(45deg);
                    margin-bottom: 0.05rem;
                }
            }

            .textarea-style {
                height: unset;
                min-height: 0.18rem;
            }

            #input::placeholder {
                font-size: 12px;
                font-family: Helvetica;
                color: $initColor;
                line-height: 16px;
                font-weight: 400;
            }

            #input:disabled {
                color: $disableColor;
                opacity: 100%;
                -webkit-text-fill-color: $disableColor;
            }

            .input-style-error {
                color: $errorColor;
            }
        }
    }

    .focus {
        border: 1px solid $focusColor;
    }

    .error {
        border: 1px solid $errorColor;
    }

    .tip {
        min-height: 16px;
        color: $initColor;
        font-size: 12px;
        font-family: Helvetica;
        line-height: 16px;
        box-sizing: border-box;
        padding-left: 0.15rem;
    }

    .bank-card {
        .search-wrap {
            width: 90%;
        }

        .bank-box {
            height: 75vh;
            overflow: auto;

            .list-item {
                box-sizing: border-box;
                padding: 0.15rem 0.1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .item-left {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .item-logo {
                        width: 0.3rem;
                        height: 0.3rem;
                    }

                    .item-text {
                        margin-left: 0.2rem;
                    }
                }

                .item-right {
                    font-size: 10px;
                    box-sizing: border-box;
                    padding: 0.02rem 0.06rem;
                    color: #fff;
                    border-radius: 0.1rem/50%;
                    background-color: #f89b0a;
                }
            }
        }
    }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-item" }, [
    _c(
      "div",
      {
        staticClass: "input-box",
        class: {
          focus: _vm.isFocus,
          error: _vm.errorTip,
        },
      },
      [
        _vm.labelActive
          ? _c(
              "div",
              {
                staticClass: "label",
                class: {
                  "focus-color": _vm.isFocus,
                  "error-color": _vm.errorTip,
                },
                style: { backgroundColor: _vm.labelBg },
              },
              [_vm._v("\n            " + _vm._s(_vm.getLabel()) + "\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-wrap" },
          [
            _c("div", { staticClass: "before" }, [_vm._t("before")], 2),
            _vm._v(" "),
            _vm.type === "textarea"
              ? _c(
                  "textarea",
                  _vm._b(
                    {
                      ref: "input",
                      staticClass: "input-style textarea-style",
                      class: { "input-style-error": _vm.errorTip },
                      style: {
                        resize: _vm.autoResizeTextarea ? "none" : "vertical",
                      },
                      attrs: {
                        id: "input",
                        placeholder: _vm.curPlaceholder,
                        rows: _vm.rows,
                        disabled: _vm.getDisabled(),
                      },
                      on: {
                        focus: _vm.onFocus,
                        blur: _vm.onBlur,
                        input: _vm.onInput,
                      },
                    },
                    "textarea",
                    _vm.$attrs,
                    false
                  )
                )
              : _vm.type === "select" ||
                _vm.type === "cascader" ||
                _vm.type === "bankCard"
              ? [
                  _c(
                    "div",
                    { staticClass: "input-style", on: { click: _vm.onClick } },
                    [
                      !_vm.labelActive
                        ? _c("span", { staticClass: "placeholder" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.getLabel()) +
                                "\n                    "
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.pickerLabel))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ec-popup",
                    {
                      attrs: {
                        closeable: _vm.type === "bankCard",
                        "close-on-click-overlay": false,
                        "content-style": {
                          background: "#f4f4f4",
                          padding: "0.1rem",
                        },
                      },
                      on: { "click-close-icon": _vm.onCancel },
                      model: {
                        value: _vm.showPopup,
                        callback: function ($$v) {
                          _vm.showPopup = $$v
                        },
                        expression: "showPopup",
                      },
                    },
                    [
                      !_vm.$slots["popup"]
                        ? [
                            _vm.type === "select"
                              ? _c(
                                  "van-picker",
                                  _vm._b(
                                    {
                                      ref: "picker",
                                      attrs: {
                                        "show-toolbar": "",
                                        "confirm-button-text":
                                          _vm.$t("form.confirm"),
                                        "cancel-button-text":
                                          _vm.$t("form.cancel"),
                                        "value-key": "label",
                                        columns: _vm.columnsData,
                                      },
                                      on: {
                                        confirm: _vm.onConfirm,
                                        cancel: _vm.onCancel,
                                      },
                                    },
                                    "van-picker",
                                    _vm.contentProps,
                                    false
                                  )
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "cascader"
                              ? _c(
                                  "van-cascader",
                                  _vm._b(
                                    {
                                      attrs: {
                                        options: _vm.columnsData,
                                        placeholder: _vm.$t("form.select"),
                                        "active-color": "#03DA8B",
                                      },
                                      on: {
                                        close: _vm.onCancel,
                                        change: _vm.onChange,
                                        finish: _vm.onFinish,
                                      },
                                      model: {
                                        value: _vm.cascaderValue,
                                        callback: function ($$v) {
                                          _vm.cascaderValue = $$v
                                        },
                                        expression: "cascaderValue",
                                      },
                                    },
                                    "van-cascader",
                                    _vm.contentProps,
                                    false
                                  )
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "bankCard"
                              ? _c("div", { staticClass: "bank-card" }, [
                                  _c(
                                    "div",
                                    { staticClass: "search-wrap" },
                                    [
                                      _c("van-search", {
                                        attrs: { background: "rgba(0,0,0,0)" },
                                        on: {
                                          input: _vm.onSearch,
                                          clear: _vm.onClear,
                                        },
                                        model: {
                                          value: _vm.searchValue,
                                          callback: function ($$v) {
                                            _vm.searchValue = $$v
                                          },
                                          expression: "searchValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "bank-box" },
                                    _vm._l(
                                      _vm.columnsDataFiletr,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "list-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectCard(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "item-left" },
                                              [
                                                _c("img", {
                                                  staticClass: "item-logo",
                                                  attrs: { src: item.logoUrl },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "item-text" },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(item.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            item.tag
                                              ? _c(
                                                  "div",
                                                  { staticClass: "item-right" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(item.tag) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._t("popup"),
                    ],
                    2
                  ),
                ]
              : _c(
                  "input",
                  _vm._b(
                    {
                      ref: "input",
                      staticClass: "input-style",
                      class: { "input-style-error": _vm.errorTip },
                      attrs: {
                        id: "input",
                        autocomplete: "off",
                        placeholder: _vm.curPlaceholder,
                        type: _vm.type,
                        disabled: _vm.getDisabled(),
                      },
                      on: {
                        focus: _vm.onFocus,
                        blur: _vm.onBlur,
                        input: _vm.onInput,
                      },
                    },
                    "input",
                    _vm.$attrs,
                    false
                  )
                ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "after" },
              [
                _vm._t("after", function () {
                  return [
                    _vm.type === "select" || _vm.type === "bankCard"
                      ? _c("div", { staticClass: "arrow" })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _vm.errorTip || (_vm.tip && _vm.isFocus)
      ? _c(
          "div",
          {
            staticClass: "tip",
            class: {
              "focus-color": _vm.isFocus,
              "error-color": _vm.errorTip,
            },
          },
          [
            _vm.isFocus && !_vm.errorTip
              ? _c("span", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t(_vm.tip)) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errorTip
              ? _c("span", [_vm._v(_vm._s(_vm.errorTip))])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }